<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'

export default {
  name: 'CrudFieldSelect',
  mixins: [BasicProps],
  props: {
    /** options used by selectors (array of {value: '', label: '',}) */
    options: {
      type: Array,
      default: undefined
    },
    /** when passed options used by selector are loaded from api (see vuex store name enums) */
    optionsEnum: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    /** an array of option values that should be visible only */
    visibleOptions: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <crud-details-field
    component="el-select"
    v-bind="$props"
  >
    <template #default>
      <slot />
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
    <template #options>
      <slot name="options" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
