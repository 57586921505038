<script>
export default {}
</script>

<template>
  <crud-table
    :columns-settings="{
      Name: { ellEdit: false },
      Start: { header: 'Start UTC' },
      End: { header: 'End UTC' },
      IsEnabledDev: { header: 'isOnDev' },
      IsEnabledProd: { header: 'isOnProd' }
    }"
    :default-visible-columns="['Name', 'Start', 'End', 'IsEnabledDev', 'IsEnabledProd']"
    api="settings/ab-tests"
    api-item="settings/ab-test"
    :versioned="false"
  />
</template>
