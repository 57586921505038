<script>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Edit } from '@element-plus/icons-vue'
import { globalProperties as app } from '!/plugins/utilities'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  setup() {
    const route = useRoute()
    const id = (route?.params?.id || 0) * 1
    const rows = ref([])
    const loading = ref({ all: false, groups: false, entity: false })
    const fetchData = (type = 'all') => {
      if (loading.value.all || loading.value.groups || loading.value.entity || !id)
        return false
      loading.value[type] = true
      app.$axios
        .get('/admin/api/ab/usage/', { params: { id } })
        .then((response) => {
          rows.value = response?.data?.items || []
        })
        .catch(app.$utils.catchError)
        .then(() => {
          loading.value[type] = false
        })
    }
    return {
      id,
      loading,
      rows,
      fetchData,
      envSettings: CrudTagsSettings(['dev', 'prod']),
      groups: computed(() => {
        const list = {}
        rows.value.forEach((v) => {
          if (!list[v.entity]) {
            const routeData = app.$utils.getRouteByEntity(v.entity)
            list[v.entity] = {
              name: v.entity,
              url: routeData ? { name: routeData.name, query: { c_sea: { ab: id } } } : null,
              total: 0
            }
          }
          list[v.entity].total++
        })
        const data = []
        for (const x in list) {
          data.push(list[x])
        }
        return data
      }),
      entities: computed(() => {
        rows.value.forEach((v) => {
          const routeData = app.$utils.getRouteByEntity(v.entity)
          v.url = routeData ? { name: routeData.name, query: { c_fil: { ID: v.id } } } : null
        })
        return rows.value
      }),
      icons: { Edit }
    }
  }
}
</script>

<template>
  <crud-details-page
    api="settings/ab-test"
    :versioned="false"
  >
    <template #form="{ form }">
      <fields-col>
        <!-- envs enabled -->
        <crud-details-field
          :sm="7"
          label=""
          api-field-name="IsEnabledDev"
        >
          <template #default>
            <div class="my-1 flex flex-wrap gap-y-1.5">
              <el-checkbox
                v-model="form.IsEnabledDev"
                size="small"
                class="gs-checkbox-mini mr-2.5 px-2"
                :class="[envSettings.dev.outlinedClasses]"
                border
              >
                Is enabled dev
              </el-checkbox>
              <el-checkbox
                v-model="form.IsEnabledProd"
                size="small"
                class="gs-checkbox-mini px-2"
                :class="[envSettings.prod.outlinedClasses]"
                border
              >
                Is enabled prod
              </el-checkbox>
            </div>
          </template>
        </crud-details-field>
        <!-- ForPlayersCreatedAfter UTC -->
        <crud-field-date-time
          :sm="5"
          label="ForPlayersCreatedAfter UTC"
          api-field-name="ForPlayersCreatedAfter"
        />
        <el-col :sm="1" />
        <!-- ForPlayersCreatedBefore -->
        <crud-field-date-time
          :sm="5"
          label="ForPlayersCreatedBefore UTC"
          api-field-name="ForPlayersCreatedBefore"
        />
        <el-col :sm="1" />
        <!-- ForCountriesCSV -->
        <crud-field-text
          :sm="5"
          api-field-name="ForCountriesCSV"
          label="ForPlayerCountries, ie: EN,PL"
        />
        <el-col :sm="7" />
        <!-- Start UTC -->
        <crud-field-date-time
          :sm="5"
          label="Start UTC"
          api-field-name="Start"
          required
        />
        <el-col :sm="1" />
        <!-- End UTC -->
        <crud-field-date-time
          :sm="5"
          label="End UTC"
          api-field-name="End"
          required
        />
        <el-col :sm="6" />
        <!-- Name -->
        <crud-field-text
          :sm="24"
          api-field-name="Name"
          required
        />
        <crud-field-textarea api-field-name="Description" />
        <crud-field-number
          v-for="(fieldNumberApiName, index) in [
            'Group1Min',
            'Group1Max',
            'Group2Min',
            'Group2Max',
            'Group3Min',
            'Group3Max',
            'Group4Min',
            'Group4Max'
          ]"
          :key="index"
          :sm="3"
          :label="fieldNumberApiName"
          :api-field-name="fieldNumberApiName"
        />
        <crud-field-number
          v-for="(fieldNumberApiName, index) in ['Modulo', 'GroupNoAfterEnd']"
          :key="index"
          :sm="4"
          :label="fieldNumberApiName"
          :api-field-name="fieldNumberApiName"
        />
      </fields-col>
    </template>
    <template #afterForm>
      <el-row
        v-if="id"
        class="gs-font-scaled mx-auto mt-10 max-w-screen-xl px-2 sm:px-10 pt-10"
      >
        <!-- Groups - list -->
        <el-col>
          <h3 class="font-related-xl text-neutral-700">
            Groups - list
          </h3>
          <filtering-table
            :rows="groups"
            :max-height="`${Math.max(500, $windowHeight * 0.7)}px`"
            class="mt-5"
            header-cell-class-name="font-medium gs-col-padding-mini"
            cell-class-name="gs-col-padding-mini"
            fit
            :columns="{
              name: { header: 'Entity', sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 220 } },
              total: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
              url: { elAttr: { width: 90 } }
            }"
            :page-sizes="[50, 100, 200]"
            :loading="loading.all || loading.groups"
            @fetch-data="fetchData('groups')"
          >
            <template #cell_url="{ row }">
              <router-link
                v-if="row.url"
                :to="$utils.bindStaticParams(row.url)"
                target="_blank"
              >
                <el-button
                  class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg px-1"
                  :icon="icons.Edit"
                />
              </router-link>
            </template>
          </filtering-table>
        </el-col>
        <!-- Entities - list -->
        <el-col>
          <h3 class="font-related-xl mt-10 text-neutral-700">
            Entities - list
          </h3>
          <filtering-table
            :rows="entities"
            :max-height="`${Math.max(500, $windowHeight * 0.7)}px`"
            class="mt-5"
            header-cell-class-name="font-medium gs-col-padding-mini"
            cell-class-name="gs-col-padding-mini"
            fit
            :columns="{
              entity: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 220 } },
              id: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } },
              label: { sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
              group: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } },
              url: { elAttr: { width: 90 } }
            }"
            :page-sizes="[50, 100, 200]"
            :loading="loading.all || loading.entity"
            @fetch-data="fetchData('entity')"
          >
            <template #cell_url="{ row }">
              <router-link
                v-if="row.url"
                :to="$utils.bindStaticParams(row.url)"
                target="_blank"
              >
                <el-button
                  class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg px-1"
                  :icon="icons.Edit"
                />
              </router-link>
            </template>
          </filtering-table>
        </el-col>
      </el-row>
    </template>
  </crud-details-page>
</template>
