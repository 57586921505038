import { createRouter, createWebHistory } from 'vue-router'
import qs from 'qs'
import { merge } from 'lodash'
import { userRightRoles } from '!/composition/utilities'

/**
 * components used by routes
 */
const MainLayout = () => import('!/layouts/MainLayout.vue')
const NotFound = () => import('!/pages/404/IndexPage.vue')
const Login = () => import('!/pages/login/IndexPage.vue')

let vue

function guardLogged(to) {
  if (
    !vue.config.globalProperties.$store.state.auth.token ||
    !vue.config.globalProperties.$store.state.auth?.userData?.Id ||
    !Object.keys(vue.config.globalProperties.$store.state.auth?.userRights).length
  ) {
    if (to?.path !== 'login') {
      localStorage.setItem(
        'login-redirect',
        JSON.stringify({
          name: to.name,
          params: to.params,
          query: to.query
        })
      )
    }
    return { name: 'login' }
  }
  return true
}

class CrudRoute {
  static enabledHipAbTests = false
  static defaultData() {
    return {
      path: '',
      component: null,
      name: '',
      beforeEnter: [guardLogged],
      meta: {
        Title: '',
        Entity: '',
        priority: 0,
        icon: '',
        isMenuItem: true,
        accessRole: userRightRoles.user,
        menuLabel: '',
        cssClass: '',
        selector: null,
        query: null,
        forcedId: null,
        IdParentSubmenu: [],
        isRemoved: false,
        visibleInMenu: true,
        parentName: ''
      }
    }
  }

  data = {
    path: undefined,
    component: undefined,
    name: undefined,
    meta: {
      Title: undefined,
      Entity: undefined,
      priority: undefined,
      icon: undefined,
      accessRole: undefined,
      menuLabel: undefined,
      cssClass: undefined,
      selector: undefined,
      query: undefined,
      forcedId: undefined,
      IdParentSubmenu: undefined,
      isRemoved: undefined,
      visibleInMenu: undefined,
      parentName: ''
    }
  }

  details = {
    title: '',
    component: null,
    isRemoved: false,
    isDetails: false,
    parentName: ''
  }

  constructor(name, path, title, component, menuLabel = undefined, icon = undefined) {
    this.setName(name)
      .setPath(path)
      .setTitle(title)
      .setPageComponents(component)
      .setMenuLabel(menuLabel)
      .setIcon(icon)
      .setIsMenuItem()
  }

  setIsMenuItem(isMenuItem = true) {
    this.data.meta.isMenuItem = isMenuItem
    return this
  }

  setIsRemoved(isRemoved = true) {
    this.data.meta.isRemoved = isRemoved
    this.details.isRemoved = isRemoved
    return this
  }

  setNotShowInMenu(yesOrNot = true) {
    this.data.meta.visibleInMenu = !yesOrNot
    return this
  }

  setName(name) {
    this.data.name = name
    return this
  }

  setPath(path) {
    this.data.path = path
    return this
  }

  setTitle(title, detailsTitle = undefined) {
    this.data.meta.Title = title
    if (detailsTitle !== undefined) {
      this.setDetailsTitle(detailsTitle)
    }
    return this
  }

  setDetailsTitle(detailsTitle) {
    this.details.title = detailsTitle
    return this
  }

  setMenuLabel(menuLabel) {
    this.data.meta.menuLabel = menuLabel
    return this
  }

  setEntity(entity) {
    this.data.meta.Entity = entity
    return this
  }

  setPageComponents(pageComponent, detailsPageComponent) {
    this.data.component = pageComponent
    if (detailsPageComponent) {
      this.details.component = detailsPageComponent
    }
    return this
  }

  setDetailsPageComponents(detailsPageComponent) {
    this.details.component = detailsPageComponent
    return this
  }

  /**
   * @param selector
   * if selector === false  columns will not be editable and there will be no search in table header
   */
  setSelector(selector) {
    this.data.meta.selector = selector
    return this
  }

  setPriority(priority) {
    this.data.meta.priority = priority
    return this
  }

  setCssClass(cssClass) {
    this.data.meta.cssClass = cssClass
    return this
  }

  setIcon(icon) {
    this.data.meta.icon = icon
    return this
  }

  setAccessRole(accessRole) {
    this.data.meta.accessRole = accessRole
    return this
  }

  setForcedId(forcedId) {
    this.data.meta.forcedId = forcedId
    return this
  }

  setQuery(query) {
    this.data.meta.query = query
    return this
  }

  setIsDetailsPage(isDetails = true) {
    this.details.isDetails = isDetails
    this.details.parentName = this.data.name
    return this
  }

  setParentName(name) {
    this.data.meta.parentName = name
    return this
  }

  getData() {
    if (this.details.isDetails) {
      const detailsRoute = merge({}, this.data)
      detailsRoute.path = this.data.path === undefined ? undefined : `${this.data.path}/details/:id(\\d+)`
      detailsRoute.name = `${this.data.name}-details`
      detailsRoute.meta.isMenuItem = false
      detailsRoute.meta.IdParentSubmenu = null
      detailsRoute.meta.priority = null
      detailsRoute.meta.icon = null
      detailsRoute.meta.Title = this.details.title
      detailsRoute.component = this.details.component
      detailsRoute.meta.isRemoved = this.details.isRemoved
      detailsRoute.meta.parentName = this.details.parentName

      return [this.data, detailsRoute]
    }
    return this.data
  }

  static buildRoute(
    name,
    component = undefined,
    title = undefined,
    menuLabel = undefined,
    icon = undefined,
    entity = undefined,
    selector = undefined
  ) {
    return new CrudRoute(name, undefined, title, component, menuLabel, icon).setEntity(entity).setSelector(selector)
  }

  static buildRoutes(
    name,
    componentListPage = undefined,
    componentDetailsPage = undefined,
    titleListPage = undefined,
    titleDetailsPage = undefined,
    icon = undefined,
    entity = undefined,
    selector = undefined
  ) {
    return new CrudRoute(name, undefined, titleListPage, componentListPage, undefined, icon)
      .setDetailsPageComponents(componentDetailsPage)
      .setDetailsTitle(titleDetailsPage)
      .setEntity(entity)
      .setSelector(selector)
      .setIsDetailsPage()
  }

  static getNettoGame() {
    if (URLSearchParams && window) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      return params?.__game ?? ''
    }
    return ''
  }

  static isEnabledHipAbTest() {
    return CrudRoute.enabledHipAbTests || ['MafiaRivals', 'MinersBrawl', 'StickTogether'].includes(CrudRoute.getNettoGame())
  }
}
class MenuItem {
  static defaultData() {
    return {
      idMenuItem: '',
      label: '',
      meta: { icon: '', priority: 0, cssClass: '' },
      routes: [],
      isSubmenu: true,
      hidden: false
    }
  }

  /**
   * on update also change defaultData
   */
  data = {
    idMenuItem: undefined,
    label: undefined,
    meta: { icon: undefined, priority: undefined, cssClass: undefined },
    routes: undefined,
    isSubmenu: undefined,
    hidden: undefined
  }

  isMenuItem = true

  constructor(idMenuItem, label, icon, routes) {
    this.setIdMenuItem(idMenuItem)
    this.setLabel(label)
    this.setIcon(icon)
    this.setRoutes(routes)
  }

  setIdMenuItem(id) {
    this.data.idMenuItem = id
    return this
  }

  setLabel(label) {
    this.data.label = label
    return this
  }

  setIcon(icon) {
    this.data.meta.icon = icon
    return this
  }

  setPriority(priority) {
    this.data.meta.priority = priority
    return this
  }

  setCssClass(cssClass) {
    this.data.meta.cssClass = cssClass
    return this
  }

  setRoutes(routes) {
    routes.forEach((route) => {
      route.meta.IdParentSubmenu = [this.data.idMenuItem]
      if (route.path === undefined) {
        if (route.name === 'dashboard') {
          route.path = '/'
        } else if (this.data.idMenuItem === route.name) {
          route.path = `/${route.name}`
        } else if (route.name.slice(0, this.data.idMenuItem.length + 1) === `${this.data.idMenuItem}-`) {
          route.path = `/${this.data.idMenuItem}/${route.name.slice(this.data.idMenuItem.length + 1)}`
        } else {
          route.path = `/${this.data.idMenuItem}/${route.name}`
        }

        if (route.name.slice(-8) === '-details') {
          route.path = `${route.path.slice(0, -8)}/details/:id(\\d+)`
        }
      }
    })
    this.data.routes = routes
    return this
  }

  setIsSubMenu(isSubMenu) {
    this.data.isSubmenu = isSubMenu
    return this
  }

  setHidden() {
    this.data.hidden = true
    return this.getData()
  }

  setMenuItemComponent(component) {
    this.data.component = component
    return this
  }

  getData() {
    return this.data
  }

  static build(idMenuItem, routes = [], icon = undefined, label = undefined) {
    return new MenuItem(idMenuItem, label, icon, routes)
  }
}

const setVue = function (Vue) {
  vue = Vue
}

export { setVue, MenuItem, CrudRoute }

export function initCommonAdminRoutes() {
  return [
    { path: '/:pathMatch(.*)*', name: '404', component: NotFound },
    { path: '/login', component: Login, name: 'login', meta: { Title: 'Login Page' } },
    {
      path: '/',
      component: MainLayout,
      beforeEnter: [guardLogged],
      children: []
    }
  ]
}

export function initRouter(Vue, routes, settings) {
  /**
   * common menu items and routes are added after application`s (ordered as last in the same group of priority);
   * the order is determined by adding items or routes to the arrays in routes.js files;
   * !!!  BUT common menu items and routes data are used as defaults !!!
   */
  const commonMenuItems = {}
  const commonVueRoutes = {}
  const finalRoutes = []
  const finalItems = []
  const usedItems = {}
  const usedVueRoutes = {}
  const usedVueDetailsRoutes = {}

  if (settings?.commonMenuItems?.length) {
    settings.commonMenuItems.forEach((item) => {
      if (item?.idMenuItem) {
        commonMenuItems[item.idMenuItem] = item
        item.routes.forEach((commonVueRoute) => {
          commonVueRoutes[commonVueRoute.name] = commonVueRoute
        })
      }
    })
    routes[2].children.push(...settings.commonMenuItems)
  }
  /**
   * create router with final routes and menu items;
   */
  for (const route of routes[2].children) {
    if (route?.idMenuItem) {
      /** * route is MenuItem */
      /**
       * merging
       */
      if (usedItems[route.idMenuItem] === undefined) {
        usedItems[route.idMenuItem] = finalItems.push(
          merge({}, MenuItem.defaultData(), commonMenuItems?.[route.idMenuItem] ? commonMenuItems[route.idMenuItem] : {}, route)
        )
        finalItems[usedItems[route.idMenuItem] - 1].routes = []
      }
      route.routes.forEach((vueRoute) => {
        if (vueRoute.meta.isMenuItem) {
          if (!usedVueRoutes[vueRoute.name]) {
            const bindRoute = merge({}, CrudRoute.defaultData(), commonVueRoutes?.[vueRoute.name] || {}, vueRoute)
            if (bindRoute?.meta?.isRemoved === true) {
              usedVueRoutes[bindRoute.name] = true
              return false
            }
            finalRoutes.push(bindRoute)
            if (bindRoute?.meta?.visibleInMenu) {
              finalItems[usedItems[route.idMenuItem] - 1].routes.push(bindRoute)
            }
          }
          usedVueRoutes[vueRoute.name] = true
        } else {
          if (!usedVueDetailsRoutes[vueRoute.name]) {
            const bindRoute = merge({}, CrudRoute.defaultData(), commonVueRoutes?.[vueRoute.name] || {}, vueRoute)
            if (bindRoute?.meta?.isRemoved !== true) {
              finalRoutes.push(bindRoute)
            }
          }
          usedVueDetailsRoutes[vueRoute.name] = true
        }
      })
    } else {
      finalRoutes.push(route)
    }
  }
  routes[2].children = finalRoutes

  /**
   * entity map (lazy)
   */
  const entityMap = {}
  const initEntityMap = (checkedRoutes) => {
    checkedRoutes.forEach((route) => {
      if (route?.meta?.Entity) {
        const entityKey = route.meta.Entity + (route.name.slice(-8) === '-details' ? '-details' : '')
        if (entityMap[entityKey]) {
          if (!entityMap[entityKey].meta.siblingsRoutes) {
            entityMap[entityKey].meta.siblingsRoutes = [entityMap[entityKey].name]
          }
          entityMap[entityKey].meta.siblingsRoutes.push(route.name)
        } else {
          entityMap[entityKey] = route
        }
      }
      if (route?.children?.length) {
        initEntityMap(route.children)
      }
    })
  }
  /**
   * create vue router
   */
  const router = createRouter({
    history: createWebHistory(),
    routes,
    menuItems: finalItems,
    /**
     * $router.options.getRouteByEntity(entityName)
     * @param entityName
     * @param detailsRoute
     * returns {route|null}
     */
    getRouteByEntity: (entityName, detailsRoute = false) => {
      if (entityMap.init !== true) {
        initEntityMap(routes)
        entityMap.init = true
      }
      return entityMap?.[entityName + (detailsRoute ? '-details' : '')] || null
    },
    parseQuery: qs.parse,
    stringifyQuery: qs.stringify
  })
  Vue.use(router)
}
