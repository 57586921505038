<script>
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'AbSelector',
  components: {
    DynamicSelector
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    CrudRoute() {
      return CrudRoute
    }
  }
}
</script>

<template>
  <DynamicSelector
    v-if="$enabledHipAbTests"
    label="AB tests"
    :columns-settings="{
      Title: { header: 'Title', filterField: 'Title_like' }
    }"
    :width-field-px="!fullWidth ? 300 : undefined"
    :full-width="fullWidth"
    api="settings/hipos"
  />
  <DynamicSelector
    v-else
    label="AB tests"
    :columns-settings="{
      Name: { header: 'Name', filterField: 'Name_like' }
    }"
    :width-field-px="!fullWidth ? 300 : undefined"
    :full-width="fullWidth"
    api="settings/ab-tests"
  />
</template>
