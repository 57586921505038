<script>
import { ArrowDownBold, ArrowUpBold, CircleClose } from '@element-plus/icons-vue'
import { computed, inject } from 'vue'
import { mapGetters } from 'vuex'
import { globalProperties as app } from '!/plugins/utilities'
import fieldsLabel from '!/components/shared/FieldsLabel.vue'

export default {
  name: 'CrudDetailsField',
  components: { CircleClose },
  provide() {
    return {
      settingsCrudDetailsField: this.settingsField
    }
  },
  props: {
    /**
     * props used by el-col component (like span, offset)
     * https://element-plus.org/en-US/component/layout.html
     */
    span: {
      type: Number,
      default: undefined
    },
    xs: {
      type: [Number, Boolean],
      default: undefined
    },
    sm: {
      type: [Number, Boolean],
      default: undefined
    },
    offset: {
      type: Number,
      default: undefined
    },
    push: {
      type: Number,
      default: undefined
    },
    pull: {
      type: Number,
      default: undefined
    },
    minHeight: {
      type: [Number, Boolean],
      default: 48
    },
    colClass: {
      type: [String, Object],
      default: undefined
    },
    /** Config props */
    label: {
      type: [String, Boolean],
      default: undefined
    },
    labelInline: {
      type: Boolean,
      default: false
    },
    /** hides the text label (but not container) */
    emptyLabel: {
      type: Boolean,
      default: false
    },
    /**
     *  it is also possible to give a path to subfields by prefix with parents fields separated dot char
     *  eg rows.0.amount
     */
    apiFieldName: {
      type: String,
      default: '',
      required: true
    },
    /**
     *  required when is not used default slot
     *   'form' is passed by props of crud-details-page form slot
     *  (for components used in default slot use v-model="form.[apiFieldName]")
     */
    form: {
      type: Object,
      default: undefined
    },
    /**
     *   'externalErrors' is passed by props of crud-details-page form slot
     */
    externalErrors: {
      type: Object,
      default: undefined
    },
    /**
     *  https://github.com/yiminghe/async-validator
     *
     *        examples:
     *           { required: true, message: 'required', trigger: 'change' },
     *           { type: 'email', message: 'not valid email', trigger: 'change' },
     *           { validator: (rule, value) => value !== 'wrong_value' }
     */
    rules: {
      type: Array,
      default: () => []
    },
    /**
     * abbreviation for using the rules prop with the required validator
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * if > 0 prop component is set as el-input with attr type="textarea"
     */
    textareaRows: {
      type: Number,
      default: 0
    },
    /**
     * the name of the support form field component
     *  e.g. el-input (is default); not supported component can be used by slot
     */
    component: {
      type: String,
      default: 'el-input'
    },
    /**
     * shortcut for use prop: component="el-input-number"
     */
    number: {
      type: Boolean,
      default: false
    },
    /** !Not defined props ($attr) are used as component field props */
    classComponent: {
      type: [String, Object],
      default: undefined
    },
    /** options used by selectors (array of {value: '', label: '',}) */
    options: {
      type: Array,
      default: undefined
    },
    /** when passed options used by selector are loaded from api (see vuex store name enums) */
    optionsEnum: {
      type: String,
      default: undefined
    },
    /** an array of option values that should be visible only  */
    visibleOptions: {
      type: Array,
      default: () => []
    },
    /** removes the bottom margin */
    slim: {
      type: Boolean,
      default: false
    },
    /** when used as filter field */
    filterMode: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    scaled: {
      type: Boolean,
      default: true
    },
    growField: {
      type: Boolean,
      default: true
    },
    nullable: {
      type: Boolean,
      default: false
    },
    disableDoc: {
      type: Boolean,
      default: false
    },
    docIconClass: {
      type: String,
      default: undefined
    },
    docFieldName: {
      type: String,
      default: undefined
    },
    disableDocSettings: {
      type: Boolean,
      default: false
    },
    colBreak: {
      type: Boolean,
      default: false
    },
    mobileBreak: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(props) {
    const crudForm = props.form || inject('crudForm')
    const initForm = props.form ? {} : inject('initForm')
    const crudDetails = inject('crudDetails', false)

    const crudExternalErrors = props.externalErrors || inject('crudExternalErrors')
    const numberMasks = computed(() => {
      if (!props.nullable) {
        if (app.$store.getters['auth/userLocalSettings']?.showZeroInNumbers === false) {
          return {
            0: ''
          }
        }
      } else {
        return {
          null: ''
        }
      }
      return {}
    })

    return {
      crudForm,
      initForm,
      crudExternalErrors,
      crudDetails,
      icons: {
        ArrowDownBold,
        ArrowUpBold
      },
      numberMasks
    }
  },
  data() {
    return {
      nameOfComponent: undefined,
      error: '',
      input: undefined,
      usedMasks: {},
      previewImage: null,
      isClearedMaskedField: false,
      settingsField: {
        type: undefined
      },
      labelTooltipRef: null,
      isFocused: false
    }
  },
  computed: {
    fieldsLabel() {
      return fieldsLabel
    },
    fieldModel: {
      get() {
        if (this.component === 'el-switch') {
          if (this.fieldValue === 'true') {
            this.$utils.setByPath(this.crudForm, this.fieldNamePath, true)
            return true
          } else if (this.fieldValue === 'false') {
            this.$utils.setByPath(this.crudForm, this.fieldNamePath, false)
            return false
          }
        }
        return this.fieldValue
      },
      set(val) {
        let emitVal
        const isDiff = val !== this.fieldValue
        switch (this.component) {
          case 'el-select':
            emitVal = val === undefined || val === null ? '' : val
            break
          case 'el-switch':
            if (val === 'true') {
              val = true
            } else if (val === 'false') {
              val = false
            }
            if (this.componentAttr?.['active-value'] === undefined) {
              emitVal = val === true
            } else {
              const inactiveValue =
                this.componentAttr?.['inactive-value'] === undefined ? false : this.componentAttr['inactive-value']
              emitVal = val === this.componentAttr['active-value'] ? this.componentAttr['active-value'] : inactiveValue
            }
            break
          default:
            emitVal = val
        }
        if (this.initForm?.diffs) {
          if (this.$utils.getByPath(this.initForm.form, this.fieldNamePath) !== emitVal) {
            this.initForm.diffs[this.apiFieldName] = true
          } else {
            delete this.initForm.diffs[this.apiFieldName]
          }
        }
        this.$utils.setByPath(this.crudForm, this.fieldNamePath, emitVal)
        this.$nextTick(() => {
          if (!this.error && isDiff) {
            this.$emit('change', emitVal)
          }
        })
      }
    },
    fieldModelMasked: {
      get() {
        return this.usedMasks[this.fieldValue] !== undefined ? this.usedMasks[this.fieldValue] : this.fieldValue
      },
      set(val) {
        if (val === '') {
          this.usedMasks = { 0: '' }
          this.isClearedMaskedField = true
        } else {
          this.usedMasks = {}
        }
        if (this.initForm?.diffs) {
          if (this.$utils.getByPath(this.initForm.form, this.fieldNamePath) !== val) {
            this.initForm.diffs[this.apiFieldName] = true
          } else {
            delete this.initForm.diffs[this.apiFieldName]
          }
        }
        this.$utils.setByPath(this.crudForm, this.fieldNamePath, val)
        this.$nextTick(() => {
          if (!this.error) {
            this.$emit('change', val)
          }
        })
      }
    },
    componentName() {
      return this.nameOfComponent || this.component
    },
    componentAttr() {
      let builtInAttr = {}
      if (this.textareaRows > 0) {
        this.forceComponent('el-input')
        builtInAttr = {
          ...builtInAttr,
          ...{
            type: 'textarea',
            rows: this.textareaRows,
            autosize: { minRows: this.textareaRows }
          }
        }
      }
      return { ...builtInAttr, ...this.$attrs }
    },
    componentSize() {
      return this.scaled ? this.$store.getters['auth/userScaledSize'] : this.$attrs?.size || 'small'
    },
    fieldName() {
      return this.label || this.$utils.convertApiNamesToHuman(this.$utils.capitalizeFirstLetter(this.fieldNamePath.slice(-1)[0]))
    },
    itemLabel() {
      if (this.emptyLabel) {
        return 'empty-label'
      }
      return (this.componentName === 'el-checkbox' && (this.slim || !this.emptyLabel)) ||
        this.label === '' ||
        this.label === false
        ? ''
        : this.fieldName
    },
    fieldNamePath() {
      return this.apiFieldName.split('.')
    },
    fieldValue() {
      return this.$utils.getByPath(this.crudForm, this.fieldNamePath)
    },
    selectOptions() {
      const options = this.options || this.$store.getters['enums/getEnum'](this.optionsEnum) || []
      if (this.visibleOptions.length) {
        const visible = this.visibleOptions.reduce((accumulator, value) => {
          return { ...accumulator, [value]: true }
        }, {})
        return options.filter((option) => {
          return !!visible?.[option?.value]
        })
      }
      return options
    },
    fieldRules() {
      const rules = [...this.rules]
      if (this.required) {
        rules.push({ required: true, message: 'required', trigger: 'change' })
      }
      if (this.number) {
        rules.push({ validator: this.checkNumberValue, trigger: 'change' })
      }
      return rules
    },
    disabledStepUp() {
      return !!this.error.length || this.$attrs.max !== undefined
        ? (this.fieldModelMasked || 0) + (this.$attrs?.step || 1) > this.$attrs.max
        : false
    },
    disabledStepDown() {
      if (this.$attrs?.min === false) {
        return false
      }
      const min = this.$attrs.min !== undefined ? this.$attrs.min : 0
      return !!this.error.length || (this.fieldModelMasked || 0) - (this.$attrs?.step || 1) < min
    },
    scaleClass() {
      const classesMap = {
        small: undefined,
        default: 'scale-110',
        large: 'scale-125'
      }
      return classesMap?.[this.$store.getters['auth/userScaledSize']]
    },
    adminIcon() {
      return this.$utils.getByPath(this.crudForm, this.fieldNamePath)?.AdminIcon || ''
    },
    ...mapGetters({
      userLocalSettings: 'auth/userLocalSettings'
    }),
    docIconClassLogic() {
      if (this.docIconClass) {
        return this.docIconClass
      }
      if (this.slim) {
        return '-right-5 -top-1 '
      }
      if (this.componentName === 'el-switch') {
        return 'top-4 left-20'
      }
      return 'right-0 -top-1'
    },
    isVisibleDocIcon() {
      const docField = this.crudDetails.docs?.[this.docFieldName || this.apiFieldName]
      return (
        !!docField?.description?.length ||
        docField?.duplicate ||
        (docField?.MinValueForNumber !== undefined && docField?.MinValueForNumber !== null) ||
        (docField?.MaxValueForNumber !== undefined && docField?.MaxValueForNumber !== null)
      )
    },
    isDuplicatedField() {
      const docField = this.crudDetails.docs?.[this.docFieldName || this.apiFieldName]
      return this.crudDetails?.duplicatedMode && docField?.duplicate
    },
    userValidWarning() {
      if (this.error) {
        return ''
      }
      const fieldSettings = this.crudDetails?.docs?.[this.apiFieldName]
      if (!fieldSettings) {
        return ''
      }
      if (this.number) {
        const emptyValues = []
        if (this.nullable) {
          emptyValues.push(null)
        }
        if (fieldSettings?.Skip0ForNumber === true) {
          emptyValues.push(0)
        }
        if (!this.$utils.isFilledField(this.fieldValue, emptyValues)) {
          return ''
        }
        if (this.$utils.isFilledField(fieldSettings?.MinValueForNumber) && this.fieldValue < fieldSettings.MinValueForNumber) {
          return `Expected min value: ${fieldSettings.MinValueForNumber}`
        }
        if (this.$utils.isFilledField(fieldSettings?.MaxValueForNumber) && this.fieldValue > fieldSettings.MaxValueForNumber) {
          return `Expected max value: ${fieldSettings.MaxValueForNumber}`
        }
      }
      return ''
    }
  },
  watch: {
    userValidWarning: {
      handler(val) {
        if (this.crudDetails.userValidationWarnings) {
          this.crudDetails.userValidationWarnings[this.apiFieldName] = val
        }
      },
      immediate: true
    }
  },
  created() {
    this.$utils.setByPath(this.crudExternalErrors, this.fieldNamePath, '')
    if (this.number) {
      this.settingsField.type = 'number'
      this.checkNumberValue('init-precision', this.fieldModelMasked)
      this.usedMasks = { ...this.numberMasks }
      if (!this.nullable && this.fieldValue === undefined) {
        this.$utils.setByPath(this.crudForm, this.fieldNamePath, 0)
      }
      this.$watch('fieldModelMasked', (val) => {
        if (val === '' && !this.isClearedMaskedField) {
          this.usedMasks = { ...this.numberMasks }
          this.$utils.setByPath(this.crudForm, this.fieldNamePath, this.nullable ? null : 0)
        }
      })
      this.$watch(
        'numberMasks',
        () => {
          this.usedMasks = { ...this.numberMasks }
        },
        { deep: true }
      )
    }
  },
  methods: {
    forceComponent(name) {
      this.nameOfComponent = name
    },
    reset() {
      this.input = ''
      this.error = ''
    },
    onChangeStep(up = true) {
      const min = this.$attrs?.min === undefined ? 0 : this.$attrs.min * 1
      const val = (this.fieldModelMasked || 0) * 1
      if ((this.fieldModelMasked === undefined || this.fieldModelMasked === null) && this.required) {
        this.fieldModelMasked = Math.max(min, 0)
        return
      }
      if (up) {
        this.fieldModelMasked = val + (this.$attrs?.step || 1)
      } else {
        this.fieldModelMasked = val - (this.$attrs?.step || 1)
      }
      this.usedMasks = { ...this.numberMasks }
    },
    checkNumberValue(rule, val) {
      let error = ''
      const isNumber = /^-?\d*(?:[.,]\d*)?$/.test(val)
      const min = this.$attrs?.min === undefined ? 0 : this.$attrs.min * 1
      const max = this.$attrs?.max === undefined ? undefined : this.$attrs.max * 1
      const precision = (this.$attrs?.precision || 0) <= 0 ? 0 : this.$attrs?.precision * 1
      const stringVal = val === undefined || val === null ? '' : `${val}`
      const numberVal = stringVal.replace(',', '.') * 1
      if (isNumber || !stringVal?.length) {
        if (min >= 0 && stringVal === '-') {
          error = 'only positive'
        } else if (val === '-' || val === ',' || val === '.') {
          error = 'only numbers'
        } else if (/^-?0\d+(?:[.,]\d*)?$/.test(stringVal)) {
          error = 'not valid'
          // eslint-disable-next-line regexp/no-super-linear-backtracking
        } else if (precision === 0 && stringVal?.length && !/^-?\d*[^.,]?\d*$/.test(stringVal)) {
          error = 'not decimal'
        } else if (this.$attrs?.min !== false && numberVal < min) {
          error = `min: ${min}`
        } else if (max !== undefined && numberVal > max) {
          error = `max: ${max}`
        } else if (
          precision > 0 &&
          stringVal?.length &&
          !new RegExp(`^-{0,1}[0-9]{0,}[.,]{0,1}[0-9]{0,${precision}}$`).test(stringVal)
        ) {
          error = `max ${precision} decimal${precision > 1 ? 's' : ''}`
          if (rule === 'init-precision') {
            this.fieldModel = numberVal.toFixed(precision) * 1
          }
        } else if (this.required && numberVal === 0 && !this.nullable) {
          error = 'required value'
        } else {
          error = ''
        }
      } else {
        error = 'only numbers'
      }
      if (!error && stringVal?.includes?.(',')) {
        this.fieldModel = stringVal.replace(',', '.')
      }
      this.error = error
      return error?.length ? [error] : true
    },
    onChangeNumber() {
      this.isClearedMaskedField = false
      this.usedMasks = { ...this.numberMasks }
    },
    parseToNumber() {
      this.isFocused = false
      this.isClearedMaskedField = false
      this.usedMasks = { ...this.numberMasks }
      if (!this.error && typeof this.fieldModel === 'string') {
        if (this.nullable) {
          this.fieldModel = this.fieldModel?.length ? this.fieldModel * 1 : null
        } else {
          this.fieldModel = this.fieldModel * 1
        }
      }
    },
    clearFieldNumber() {
      this.isFocused = false
      this.fieldModelMasked = ''
      this.$refs?.crudField?.focus?.()
    },
    onBlurField() {
      this.isFocused = false
    },
    onFocusSelect() {
      this.isFocused = true
      this.$utils.nextLoopEvent(50).then(() => {
        if (this.isFocused) {
          this.$refs?.crudField?.select?.()
        }
      })
    },
    initDynamicTitleLabel(ev) {
      if (this.$windowWidth < 640) {
        return false
      }
      const label = ev.target?.querySelector?.('label')
      if (label) {
        if (label.scrollWidth > ev.target.clientWidth) {
          this.labelTooltipRef = label
          label.classList.add('cursor-help')
        }
        label.addEventListener(
          'mouseenter',
          (mouseEv) => {
            if (mouseEv.target.scrollWidth > ev.target.clientWidth) {
              this.labelTooltipRef = label
              label.classList.add('cursor-help')
            } else {
              this.labelTooltipRef = null
              label.classList.remove('cursor-help')
            }
          },
          false
        )
      }
    }
  }
}
</script>

<template>
  <div class="contents">
    <el-col
      :span="span"
      :xs="xs === true ? 24 : xs"
      :sm="sm === true ? 24 : sm"
      :offset="offset"
      :push="push"
      :pull="pull"
      :class="[colClass, { 'px-0': slim || filterMode }]"
      v-bind="$attrs"
    >
      <div class="is-justify-space-between relative flex w-full flex-nowrap items-center">
        <div
          v-if="
            !!crudDetails
              && (docFieldName || apiFieldName)
              && (crudDetails.docMode || isVisibleDocIcon)
              && !crudDetails.disableDoc
              && !disableDoc
          "
          class="absolute"
          style="z-index: 1"
          :class="[docIconClassLogic]"
        >
          <el-tooltip
            effect="light"
            placement="top"
            content="field documentation"
            :show-after="600"
          >
            <el-button
              size="small"
              class="gs-font-scaled gs-height-related-xl cursor-pointer bg-transparent px-0 py-0"
              :class="[isDuplicatedField ? 'gs-btn-text-success' : 'gs-btn-text-primary-light']"
              @click.stop="
                crudDetails.openDrawerDocumentation(
                  docFieldName || apiFieldName,
                  `Field : ${docFieldName || itemLabel || apiFieldName}`,
                  true,
                  settingsField,
                  disableDocSettings
                )
              "
            >
              <icon-ify
                icon="mdi:chat-help-outline"
                class="h-4 w-4"
              />
            </el-button>
          </el-tooltip>
        </div>
        <div
          v-if="adminIcon"
          class="flex items-center leading-none"
        >
          <el-tooltip
            raw-content
            :show-after="600"
            effect="light"
            placement="right-start"
          >
            <template #default>
              <el-image
                :src="`https://csndmapzfa.cloudimg.io/${adminIcon.replace('https://', '')}?w=100`"
                fit="contain"
                class="mr-1"
                :preview-src-list="[adminIcon]"
                :style="{
                  width: `${
                    26 * $store.getters['auth/userScaledRatioWidth']
                    + (26
                      * $store.getters['auth/userScaledRatioWidth']
                      * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                    / 100
                  }px`,
                  height: `${
                    25 * $store.getters['auth/userScaledRatioWidth']
                    + (26
                      * $store.getters['auth/userScaledRatioWidth']
                      * ($store.getters['auth/userLocalSettings']?.imgPreviewScale || 1))
                    / 100
                  }px`
                }"
                preview-teleported
                hide-on-click-modal
              />
            </template>
            <template #content>
              <el-image
                :src="`https://csndmapzfa.cloudimg.io/${adminIcon.replace('https://', '')}?w=200`"
                fit="contain"
                style="width: 200px; height: 200px"
              />
            </template>
          </el-tooltip>
        </div>
        <el-form-item
          class="gs-field grow max-w-full"
          :class="{
            'flex': labelInline,
            'slim mb-0': slim || filterMode,
            'no-label': (componentName === 'el-checkbox' && (slim || !emptyLabel)) || label === '' || label === false,
            'gs-font-scaled': scaled,
            'empty-label': emptyLabel,
            'filter-mode flex-col': filterMode
          }"
          :label="itemLabel"
          :prop="apiFieldName"
          :rules="fieldRules"
          :error="$utils.getByPath(crudExternalErrors, fieldNamePath)"
          :size="componentSize"
          :style="minHeight && !slim ? `min-height: ${minHeight}px` : ''"
          @mouseenter.once="initDynamicTitleLabel"
        >
          <!-- label -->
          <template #label="props">
            <slot
              name="label"
              :label="props.label"
            />
            <template v-if="componentName === 'el-switch'">
              {{ itemLabel }}
              <div
                class="absolute inset-0"
                @click.stop.prevent
              />
            </template>
          </template>
          <!-- field wrapper -->
          <div
            class="flex w-full grow items-center leading-none"
            :class="{ 'flex-col pt-[1px]': vertical }"
          >
            <!-- fieldPrepend -->
            <div class="h-fit leading-none">
              <slot name="fieldPrepend" />
            </div>
            <!-- fields types -->
            <div :class="{ 'grow': growField, 'w-full': vertical }">
              <slot
                :form="crudForm"
                :api-field-name="apiFieldName"
              >
                <!-- number -->
                <div
                  v-if="number"
                  class="gs-input-number relative"
                  :class="{ isError: error.length }"
                >
                  <div class="relative inline-block w-full">
                    <el-input
                      ref="crudField"
                      v-model="fieldModelMasked"
                      :size="componentSize"
                      type="text"
                      v-bind="componentAttr"
                      :class="[classComponent ? classComponent : 'w-full', { 'gs-number-zero': fieldValue === 0 }]"
                      :input-style="fieldValue === 0 ? 'color: rgb(156 163 175);' : undefined"
                      @keydown.down.stop
                      @keydown.up.stop
                      @change="onChangeNumber"
                      @blur="parseToNumber"
                      @focus="onFocusSelect"
                    />
                    <div
                      v-if="!$attrs?.disabled"
                      class="invisible absolute bottom-0 right-0 top-0 leading-none"
                    >
                      <div class="h-1/2">
                        <el-button
                          :icon="icons.ArrowUpBold"
                          :disabled="disabledStepUp"
                          size="small"
                          class="block h-full p-0 leading-none"
                          @click="onChangeStep"
                        />
                      </div>
                      <div class="h-1/2">
                        <el-button
                          :icon="icons.ArrowDownBold"
                          :disabled="disabledStepDown"
                          size="small"
                          class="block h-full p-0 leading-none"
                          @click="onChangeStep(false)"
                        />
                      </div>
                    </div>
                  </div>
                  <el-icon
                    v-if="
                      clearable
                        && (fieldModelMasked || (fieldModelMasked !== '' && fieldModelMasked !== undefined))
                        && !$attrs?.disabled
                    "
                    class="invisible absolute cursor-pointer bg-white"
                    @click.stop="clearFieldNumber"
                  >
                    <CircleClose />
                  </el-icon>
                </div>
                <!-- text input -->
                <el-input
                  v-else-if="componentName === 'el-input'"
                  ref="crudField"
                  v-model="fieldModel"
                  v-bind="componentAttr"
                  :class="classComponent"
                  clearable
                  @blur="onBlurField"
                  @focus="onFocusSelect"
                />
                <el-switch
                  v-else-if="componentName === 'el-switch'"
                  v-model="fieldModel"
                  :class="classComponent"
                  inline-prompt
                  active-text="Y"
                  inactive-text="N"
                  inactive-color="#d4d4d4"
                  active-color="#13ce66"
                  v-bind="componentAttr"
                />
                <el-checkbox
                  v-else-if="componentName === 'el-checkbox'"
                  v-model="fieldModel"
                  v-bind="componentAttr"
                  :class="classComponent"
                >
                  {{ label === false ? '' : fieldName }}
                </el-checkbox>
                <!-- empty slot -->
                <template v-else-if="componentName === 'empty-slot'" />
                <el-select
                  v-else-if="componentName === 'el-select'"
                  v-model="fieldModel"
                  tag-type="info"
                  v-bind="componentAttr"
                  :class="classComponent"
                  :multiple="$attrs?.multiple !== undefined && $attrs?.multiple !== false ? true : false"
                  placeholder=" "
                  :collapse-tags="$attrs?.['collapse-tags'] !== undefined && $attrs?.['collapse-tags'] !== false ? true : false"
                  collapse-tags-tooltip
                  filterable
                  :clearable="clearable"
                  style="width: 100%"
                >
                  <slot name="options">
                    <el-option
                      v-for="item in selectOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="!!item?.disabled"
                    />
                  </slot>
                  <template #footer>
                    <slot name="footer" />
                  </template>
                </el-select>
              </slot>
            </div>
            <!-- fieldAppend -->
            <div
              class="h-fit leading-none"
              :class="{ 'w-full': vertical }"
            >
              <slot
                name="fieldAppend"
                :size="componentSize"
              />
            </div>
          </div>
        </el-form-item>
        <div
          v-if="userValidWarning"
          class="font-related-xss absolute text-orange-500"
          :class="{ 'bottom-1 left-3': !slim, '-bottom-1.5 -left-2': slim }"
        >
          <template v-if="slim">
            <el-tooltip
              effect="light"
              placement="top"
              :content="userValidWarning"
              :show-after="600"
            >
              <icon-ify
                icon="mdi:alert"
                class="gs-scaled-icon-xs cursor-help text-orange-400"
                @click.stop
              />
            </el-tooltip>
          </template>
          <template v-else>
            {{ userValidWarning }}
          </template>
        </div>
      </div>
    </el-col>
    <el-col v-if="colBreak" />
    <el-col
      v-if="mobileBreak"
      class="sm:hidden"
    />
    <el-tooltip
      v-if="labelTooltipRef && !emptyLabel && itemLabel"
      placement="top-start"
      effect="light"
      :virtual-ref="labelTooltipRef"
      virtual-triggering
      :offset="5"
      :show-after="300"
    >
      <template #content>
        {{ itemLabel }}
      </template>
    </el-tooltip>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.gs-field) {
  &.slim {
    .el-form-item__error {
      position: static !important;
    }
  }
  .gs-input-number {
    .el-input__inner {
      padding-left: 3px;
      padding-right: 3px;
    }

    .el-icon.invisible {
      top: -1px;
      left: -2px;
    }
    &:hover {
      .invisible {
        visibility: visible !important;
      }
    }
  }
  label {
    position: relative;
  }
  &.filter-mode {
    min-height: auto !important;
    margin-left: 10px;
    label {
      font-size: 0.7em;
      line-height: 1.25 !important;
      height: 1.25em !important;
      justify-content: flex-start !important;
      --tw-text-opacity: 1 !important;
      color: rgb(163 163 163 / var(--tw-text-opacity)) !important;
    }
    .el-form-item__content {
      flex: 0 !important;
    }
  }
  @media (min-width: 640px) {
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
